<template>
    <div>
      <!-- Table Container Card -->
      <b-card
        no-body
        class="mb-0"
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>

          <b-col cols="12">

            <h4>History Stock Item {{ itemName }} Shelve : {{ shelfName }}</h4>
            <br>
            <b-button
              type="button"
              variant="primary"
              class="mr-1"
              @click="previous()"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="16"
                class="align-middle"
              />
              Back
            </b-button>
            <div class="devider" />
            </b-col>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <label>Show</label>
              <v-select
                v-model="perPage"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <label>entries</label>
            </b-col>
          </b-row>
        </div>
        <b-table
          ref="refStockListTable"
          class="position-relative"
          :items="fetchStocks"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No matching records found"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- Column: Type -->
          <template #cell(Type)="data">
            <div class="text-nowrap">
              <span
v-if="data.item.stock_detail_type=='IN'"
                  class="align-text-top text-capitalize success"
>
                <feather-icon
                  icon="ArrowDownIcon"
                  size="16 "
                  class="align-middle success"
                />
                 {{ data.item.stock_detail_type }}</span>
                <span
v-if="data.item.stock_detail_type=='OUT'"
                  class="align-text-top text-capitalize danger"
>
                  <feather-icon
                  icon="ArrowUpIcon"
                  size="16"
                  class="align-middle danger"
                /> {{ data.item.stock_detail_type }}</span>
            </div>
          </template>
          <!-- Column: Qty -->
          <template #cell(Qty)="data">
              {{ data.item.stock_detail_item_qty }}
          </template>
          <!-- Column: Balance -->
          <template #cell(Balance)="data">
              {{ data.item.stock_detail_item_balance?data.item.stock_detail_item_balance : '0' }}
          </template>
          <template #cell(Description)="data">
            {{ data.item.stocks ? data.item.stocks.stock_description: '' }}
          </template>
          <!-- Column: DateCreated -->
          <template #cell(DateCreated)="data">
              {{ dateSimple(data.item.stock_detail_created_time, 'YYYY-MM-DD HH:mm:ss') }}
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalStocks"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
  </template>
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
    BBadge, BDropdown, BDropdownItem, BPagination, BModal, VBModal, BListGroup, BListGroupItem,
  } from 'bootstrap-vue'
  import axios from 'axios'
  import vSelect from 'vue-select'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { ref } from '@vue/composition-api'
  import { avatarText } from '@core/utils/filter'
  import moment from 'moment'
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import StockList from './Stocks'

  const userRole = ''
  const messageBox = ''
  const to = ''
  const cc = ''
  const reason = ''
  const StockId = ''
  const itemName = ''
  const shelfName = ''
  const messagesList = []
  const statusItems = []
  const items = []
  const modalDetailShow = false
  const modalMessage = false
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      // StockesListFilters,
      BModal,
      VBModal,
      BCard,
      BRow,
      BCol,
      BFormInput,
      BButton,
      BTable,
      BMedia,
      BAvatar,
      BLink,
      BBadge,
      BDropdown,
      BDropdownItem,
      BPagination,
      vSelect,
    },
    data() {
      return {
        shelfName,
      items,
      statusItems,
      StockId,
      messagesList,
      }
    },
    mounted() {
    const item = JSON.parse(localStorage.getItem('ItemDetails'))
    const shelf = localStorage.getItem('ShelfDetails')
     this.itemName = item.item_name
     this.shelfName = shelf
      this.$root.$on('refreshTable', text => {
      console.log(text) // here you need to use the arrow function
      this.$refs.refStockListTable.refresh()
    })
    },
    setup() {
      const isAddNewStockSidebarActive = ref(false)
      const statusOptions = [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ]
      const {
        fetchStocks,
        tableColumns,
        perPage,
        currentPage,
        totalStocks,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refStockListTable,
        refetchData,
        // UI
        resolveStockRoleVariant,
        resolveStockRoleIcon,
        resolveStockStatusVariant,
        // Extra Filters
        roleFilter,
        statusFilter,
      } = StockList()
      const roleOptions = [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ]
      return {
        itemName,
        to,
        cc,
        reason,
        messageBox,
        modalDetailShow,
        modalMessage,
        // Sidebar
        isAddNewStockSidebarActive,
        userRole,
        fetchStocks,
        tableColumns,
        perPage,
        currentPage,
        totalStocks,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refStockListTable,
        refetchData,
        // Filter
        avatarText,
        // UI
        resolveStockRoleVariant,
        resolveStockRoleIcon,
        resolveStockStatusVariant,
        roleOptions,
        statusOptions,
        // Extra Filters
        roleFilter,
        statusFilter,
      }
    },
    methods: {
        deleteStock(id) {
          const userToken = this.$cookies.get('userToken')
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${userToken}`,
  }
            axios
                .get(`${process.env.VUE_APP_API_DELETE_MATERIAL_REQUEST}${id}`, { headers })
                .then(response => {
                  console.log(response)
                  if (response.data.success === true) {
                    this.$refs.refStockListTable.refresh()
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Congratulation',
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: 'Your data has been deleted!',
                      },
                    })
                  } else {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: 'Notification',
                        icon: 'BellIcon',
                        variant: 'danger',
                        text: 'Something went wrong',
                      },
                    })
                  }
                })
                .catch(err => {
                    console.log(err)
                })
        },
        editStock(data) {
            this.$cookies.set('StockEdit', data)
            this.$router.push({ name: 'apps-stock-edit' })
        },
        confirmStock(id) {
      const userToken = this.$cookies.get("userToken")
const headers = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${userToken}`,
}
          const body = {
          time: moment(),
          }
      axios
        .post(`${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CONFIRM_PURCHASE_ORDER}${id}`, body, { headers })
        .then(async response => {
          if (response.data.success === true) {
            this.$refs.refStockListTable.refresh()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Confirm Purchase Order Success',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          }
          })
        },
        sendStock(data) {
            this.$cookies.set('StockSend', data)
            this.$router.push({ name: 'apps-stock-send' })
        },
      addNew() {
            this.$router.push({ name: 'apps-stock-create' })
      },
    previous() {
      window.history.back()
    },
      dateSimple(value, format = 'YYYY-MM-DD') {
          let dateRet = ''
          if (value !== null) {
            dateRet = moment(String(value)).format(format)
          } else { dateRet = null }
          return dateRet
      },
      hasPermission(permission) {
        const permissions = this.$cookies.get('userPermissions').split(',')
        return permissions.includes(permission) ? true : false
      },
      async receivePurchasingStock(item) {
        this.$cookies.set('StockDetail', item)
        this.$router.push({ name: 'apps-stock-receive-purchasing' })
      },
    },
  }
  </script>
  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>